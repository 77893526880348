<template>
  <!-- 联系我们 -->
  <div class="ziye_gbeij">
    <div class="px1200">
      <div class="dangq_luj px1200"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/contactUs">联系我们</my-link></span></div>
      <div class="chanp_biapt">
        <h2>联系我们</h2>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="contactus-logo" :id="mapId"></div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="contactus-email-title">联系我们，让我们为您提供最优质的服务助您迈向辉煌</div>
          <div class="contactus-email">
            <ul>
              <li>
                <span>
                  总部地址：
                </span>
                {{ commonUtils.getDictName('web_display_config', 'address') }}
              </li>
              <li>
                <span>联系电话：</span>{{ commonUtils.getDictName('web_display_config', 'mobile') }}
              </li>
              <li>
                <span>全国品牌管理中心：</span>{{ commonUtils.getDictName('web_display_config', 'manage_center') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="contactus">-->
<!--    <div class="wrap-lg">-->
<!--      <div class="">-->
<!--        <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">联系我们</span></div>-->
<!--      </div>-->
<!--      <div class="clearfix">-->
<!--        <div class="contactus-left">-->
<!--          <div class="contactus-logo" :id="mapId">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="contactus-right">-->
<!--          <div class="contactus-email-title">联系我们，让我们为您提供最优质的服务助您迈向辉煌</div>-->
<!--          <div class="contactus-email">-->
<!--            <ul>-->
<!--              <li>-->
<!--                <span>-->
<!--                  总部地址：-->
<!--                </span>-->
<!--                {{ commonUtils.getDictName('web_display_config', 'address') }}-->
<!--              </li>-->
<!--              <li>-->
<!--                <span>联系电话：</span>{{ commonUtils.getDictName('web_display_config', 'mobile') }}-->
<!--              </li>-->
<!--              <li>-->
<!--                <span>全国品牌管理中心：</span>{{ commonUtils.getDictName('web_display_config', 'manage_center') }}-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import loadBMap from '@/utils/map.js'
import commonUtils from '@/utils/common-utils'

export default {
  name: 'help',
  data () {
    return {
      mapId: 'BMap-' + parseInt(Date.now() + Math.random()),
      myMap: null
    }
  },
  mounted () {
    this.initMap()
  },
  methods: {
    initMap () {
      loadBMap('uihZTRVFE84P8gxwxKdmBlppBOHQBvFP')
        .then(() => {
          var _MyMap = this.myMap
          const x = commonUtils.getDictName('web_display_config', 'x')
          const y = commonUtils.getDictName('web_display_config', 'y')
          _MyMap = new BMap.Map(this.mapId) // 创建Map实例
          var point = new BMap.Point(x, y)
          var marker = new BMap.Marker(point)
          _MyMap.addOverlay(marker)
          _MyMap.centerAndZoom(point, 11) // 初始化地图,设置中心点坐标和地图级别
          _MyMap.addControl(
            new BMap.MapTypeControl({
              // eslint-disable-next-line no-undef
              mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
            })
          )
          _MyMap.enableScrollWheelZoom(true)
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.contactus {
  width: 100%;
  min-width: 1226px;
  padding: 10px 0 10px;
  box-sizing: border-box;
  margin: 0 auto;
  line-height: 1;

  .contactus-left {
    width: 500px;
    float: left;
    margin-left: 30px;

    .contactus-logo {
      height: 350px;
      padding-left: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .contactus-right {
    float: left;
    margin-left: 50px;

    .contactus-email-title {
      color: #4f080e;
      font-weight: bold;
      margin-top: 30px;
      font-size: 16px;
      border-bottom: 1px solid #4f080e;
      padding-bottom: 8px;
    }

    .contactus-email {
      //width: 500px;
      padding-right: 20px;
      margin-top: 50px;
    }

    .contactus-email li {
      line-height: 25px;
      margin-bottom: 17px;
      list-style: none;
      font-size: 14px;
      color: #330006;
      padding: 0;

      a {
        text-decoration: none;
      }
    }

    .contactus-email li span {
      font-weight: bold;
      font-size: 16px;
      color: #330006;
    }

  }
}

.contactus .contactus-title {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 58px;
}

.contactus ul a,
.contactus ul span,
.contactus ul em,
.contactus ul label {
  color: #ffffff;
  font-size: 14px;
}

.contactus ul a:hover {
  font-weight: bold;
}

.contactus ul label {
  display: inline-block;
  position: relative;
  top: -21px;
}

.contactus ul em {
  display: inline-block;
  line-height: 22px;
  width: 157px;
  height: 44px;
}

.contactus .QRcode {
  float: right;
  width: 268px;
  padding-left: 52px;
  margin-top: 40px;
}

.contactus .QRcode .QRcode_text {
  float: left;
  line-height: 30px;
  color: #330006;
}

.contactus .QRcode .QRcode_img {
  margin-right: 35px;
}

.contactus .QRcode_img_left {
}

.contactus .QRcode .QRcode_img.m-r {
  margin-right: 0;
}

.contactus .QRcode .QRcode_img img {
  width: 114px;
  height: 114px;
}

.contactus .QRcode .QRcode_img span {
  width: 114px;
  text-align: center;
  font-size: 14px;
  color: #606266;
  display: block;
}

.contactus .contactus_seo {
  padding-top: 35px;
  font-size: 14px;
  display: inline-flex;
}

.contactus .contactus_seo * {
  color: #606266;
}

.contactus .contactus_seo a:hover {
  color: #303133;
}
.contactus-logo {
  height: 350px;
  padding-left: 40px;
  margin-top: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}
.contactus-email-title {
  color: #4f080e;
  font-weight: bold;
  margin-top: 30px;
  font-size: 18px;
  border-bottom: 1px solid #4f080e;
  padding-bottom: 8px;
  text-align: center;
}

.contactus-email {
  width: 500px;
  padding-right: 20px;
  margin-top: 50px;
}

.contactus-email li {
  line-height: 25px;
  margin-bottom: 17px;
  list-style: none;
  font-size: 14px;
  color: #330006;
  padding: 0;

  a {
    text-decoration: none;
  }
}

.contactus-email li span {
  font-weight: bold;
  font-size: 16px;
  color: #330006;
}
</style>
